import {useLocation, useNavigate} from "react-router-dom";
import {LoaderWithFetch} from "../components/Loader";
import {Checkout} from "./CheckoutPlanPage";

export interface CheckoutCreditPurchaseState {
    creditPurchaseId: number;
    testMode: boolean;
}

interface CheckoutCreditPurchaseResponse {
    redirect?: string;
    clientSecret?: string;
    stripePublishableApiKey?: string;
}

export default function CheckoutCreditPurchasePage() {
    let navigate = useNavigate();
    const location = useLocation()

    if (!location.state) {
        navigate('/plans')
        return null
    }

    const state = location.state as CheckoutCreditPurchaseState

    let url = `/api/v1/${state.testMode ? "test/" : ""}checkoutCreditPurchase?creditPurchaseId=${state.creditPurchaseId}`;

    return (
        <LoaderWithFetch<CheckoutCreditPurchaseResponse> url={url}>
            {response => {
                if (response.redirect) {
                    window.location.href = response.redirect
                    return null
                }
                const clientSecret = response.clientSecret!!
                const stripePublishableApiKey = response.stripePublishableApiKey!!

                return (
                    <Checkout clientSecret={clientSecret} stripePublishableApiKey={stripePublishableApiKey}/>
                )
            }}
        </LoaderWithFetch>
    )
}