import React, {useEffect, useState} from "react";
import {Loader} from "./Loader";
import {BgShape003} from "./BgShapes";

export function TermsOfService() {
    const [loading, setLoading] = useState<boolean>(false);
    const [text, setText] = useState<string>("");

    useEffect(() => {
        setLoading(true);
        fetch("/tos-text.txt")
            .then(response => response.text())
            .then(text => {
                setText(text);
                setLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setText("Failed to load TOS");
            });
    }, []);

    return (
        <section className={"section"}>
            <Loader loading={loading}>
                <div className="container-xl">
                    <div className="style-header bg-neutral-800">
                        <div className="bg-pattern-wrap"><BgShape003/></div>
                        <div className="content-centered">
                            <h1 className="title-lg">Terms of Service</h1>
                        </div>
                    </div>
                </div>
                <div className="container-xl">
                    <div className="section">
                        <div className="w-layout-grid column-lg max-width-full auto">
                            <pre style={{textWrap: "pretty"}}>{text}</pre>
                        </div>
                    </div>
                </div>
            </Loader>
        </section>
    );
}