import bgShape001 from "../images/bg-shape-001.png";
import bgShape001_500 from "../images/bg-shape-001-p-500.png";
import bgShape001_800 from "../images/bg-shape-001-p-800.png";
import bgShape001_1080 from "../images/bg-shape-001-p-1080.png";
import bgShape001_1600 from "../images/bg-shape-001-p-1600.png";
import bgShape001_2000 from "../images/bg-shape-001-p-2000.png";
import bgShape001_2600 from "../images/bg-shape-001-p-2600.png";

import bgShape003 from "../images/bg-shape-003.png";
import bgShape003_500 from "../images/bg-shape-003-p-500.png";
import bgShape003_800 from "../images/bg-shape-003-p-800.png";
import bgShape003_1080 from "../images/bg-shape-003-p-1080.png";
import bgShape003_1600 from "../images/bg-shape-003-p-1600.png";
import bgShape003_2000 from "../images/bg-shape-003-p-2000.png";
import bgShape003_2600 from "../images/bg-shape-003-p-2600.png";

import bgShape006 from "../images/bg-shape-006.png";
import bgShape006_500 from "../images/bg-shape-006-p-500.png";
import bgShape006_800 from "../images/bg-shape-006-p-800.png";
import bgShape006_1080 from "../images/bg-shape-006-p-1080.png";
import bgShape006_1600 from "../images/bg-shape-006-p-1600.png";
import bgShape006_2000 from "../images/bg-shape-006-p-2000.png";

export function BgShape001() {
    return (
        <img src={bgShape001} loading="lazy"
             sizes="100vw"
             srcSet={`${bgShape001_500} 500w, ${bgShape001_800} 800w, ${bgShape001_1080} 1080w, ${bgShape001_1600} 1600w, ${bgShape001_2000} 2000w, ${bgShape001_2600} 2600w, ${bgShape001} 3200w`}
             alt="" className="bg-pattern-image"/>
    )
}

export function BgShape003() {
    return (
        <img src={bgShape003} loading="lazy"
             sizes="100vw"
             srcSet={`${bgShape003_500} 500w, ${bgShape003_800} 800w, ${bgShape003_1080} 1080w, ${bgShape003_1600} 1600w, ${bgShape003_2000} 2000w, ${bgShape003_2600} 2600w, ${bgShape003} 3200w`}
             alt="" className="bg-pattern-image"/>
    )
}

export function BgShape006() {
    return (
        <img src={bgShape006} loading="lazy"
             sizes="100vw"
             srcSet={`${bgShape006_500} 500w, ${bgShape006_800} 800w, ${bgShape006_1080} 1080w, ${bgShape006_1600} 1600w, ${bgShape006_2000} 2000w, ${bgShape006} 2600w`}
             alt="" className="bg-pattern-image"/>
    )
}