import React from "react";
import {NavLink} from "react-router-dom";

export function NavFooter() {
    return (
        <div className="footer-1-dark-with-cta">
            <div className="container-lg">
                <div className="w-layout-grid footer-grid">
                    <div className='centered' style={{minHeight: "5vh"}}>
                        <div className="social-icons">
                            <a href="https://discord.gg/Qa9vYdE78a" target="_blank"
                               className="text-decoration-none text-light" rel="noreferrer"><i
                                className="fab fa-discord"></i></a>
                            <a href="https://www.youtube.com/@CodebuddyAI" target="_blank"
                               className="text-decoration-none text-light" rel="noreferrer"><i
                                className="fab fa-youtube"></i></a>
                            <a href="https://twitter.com/CodebuddyAI" target="_blank"
                               className="text-decoration-none text-light" rel="noreferrer"><i
                                className="fab fa-twitter"></i></a>
                        </div>
                        <hr className="w-25 mx-auto"/>
                        <p className="w-75 mx-auto">
                            If you have any questions or concerns, please don't hesitate to contact us. The best way to
                            reach us is through
                            our Discord server. You can also
                            send us a message through our contact form. We will get back to you as soon as possible.
                            <br/>
                            <br/>
                            <NavLink to={"/contact"} className="text-decoration-none text-light">
                                <i className="fas fa-envelope"></i> Contact Us
                            </NavLink>
                        </p>
                        <div className="tos-privacy-links">
                            <NavLink to={"/terms-of-service"} className="text-decoration-none text-light">
                                Terms of Service
                            </NavLink>
                            &nbsp;•&nbsp;
                            <NavLink to={"/privacy-policy"} className="text-decoration-none text-light">
                                Privacy Policy
                            </NavLink>
                        </div>

                        <br/>
                        <p className="text-center" style={{fontSize: "smaller"}}>
                            &copy; 2023 Codebuddy AI Inc. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}