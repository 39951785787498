import {LoaderWithFetch} from "../components/Loader";
import {BlogEntries, BlogOverviewEntry} from "../dao/Blog";


export default function BlogListPage() {
    return (
        <>
            <LoaderWithFetch<BlogEntries> url={"/api/v1/blogList"}>
                {response => {
                    return (
                        <div className="container">
                            <div className="blog-entries-container">
                                <h1 className="text-center mb-4">Latest Blog Entries</h1>
                                {
                                    response.entries.length === 0 && (
                                        <div className="text-center">No blog entries found</div>
                                    )
                                }
                                <div className={"blog-entries"}>
                                    {
                                        response.entries.map(entry => {
                                            return (
                                                <BlogListEntry entry={entry} key={entry.url}/>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }}
            </LoaderWithFetch>
        </>
    )
}

export function BlogListEntry(props: { entry: BlogOverviewEntry }) {
    const entry = props.entry;
    return (
        <a className="blog-entry"
           style={{ // the (23, 30, 37) comes from --var(--neutral-800)
               backgroundImage: "linear-gradient(to right, rgba(23, 30, 37, 0), rgba(23, 30, 37, 1)), url('" + entry.ogImageUrl + "')"
           }}
           href={`/blog/${entry.url}`}
           key={entry.url}
        >
            <h3 className="blog-title">
                {entry.shortTitle}
            </h3>
            {
                entry.timestamp && `Published on ${new Date(entry.timestamp).toDateString()}`
            }
        </a>
    )
}