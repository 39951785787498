import React from "react";
import {JetbrainsButton, VSCodeButton} from "./Frontpage";
import {BgShape003} from "../components/BgShapes";

export default function StartCodingPage() {
    return (
        <section className="section portal-page">
            <div className="container-xl">
                <div className="style-header bg-neutral-800" style={{padding: "2em", marginBottom: "0"}}>
                    <div className="bg-pattern-wrap"><BgShape003/></div>
                    <div className="content-centered">
                        <h1 className="title-lg">Start Coding</h1>
                    </div>
                </div>
            </div>
            <div className="container-sm padding-lg">
                <div className="container">
                    <JetbrainsButton/>
                    <VSCodeButton/>
                </div>
                <div className="w-layout-blockcontainer container w-container">
                    <a href="/home" className="button-animated w-inline-block">
                        <div className="button-label">Web Version (deprecated)</div>
                        <div className="button-hover-background"></div>
                    </a>
                </div>
            </div>
            <div className="container-sm padding-lg">
                <h2>Chrome Extension</h2>
                <p>Use the CodeBuddy Chrome extension to extract the current page and send it to the Codebuddy Plugins
                    in both Jetbrains and VSCode.</p>
                <div className="w-layout-blockcontainer container w-container">
                    <a href="https://chromewebstore.google.com/detail/codebuddy/fhodgjaghckjpbhlhebdnolbffodjlho"
                       className="button-animated w-inline-block">
                        <div className="button-label">Install from the Chrome Webstore</div>
                        <div className="button-hover-background"></div>
                    </a>
                </div>
            </div>
        </section>
    )
}