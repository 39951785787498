import {Form, Input, Label} from "reactstrap";
import AnimatedButton from "../components/AnimatedButton";
import {useState} from "react";
import {Loader} from "../components/Loader";
import {NotifyPopup} from "../components/Popups";
import {useNavigate} from "react-router-dom";

export function ContactUsPage() {
    const [email, setEmail] = useState<string>('')
    const [subject, setSubject] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [sending, setSending] = useState<boolean>(false)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)

    const navigate = useNavigate()

    const navigateHome = () => {
        navigate('/')
    }

    const submitForm = () => {
        if (email.trim() === '' || subject.trim() === '' || message.trim() === '') {
            setShowError(true)
            return
        }
        setSending(true)
        const formData = new URLSearchParams()
        formData.append('email', email)
        formData.append('subject', subject)
        formData.append('message', message)

        fetch('/cmd?cmd=SubmitContactUsForm', {
            method: 'POST',
            body: formData
        }).then(() => {
            setSending(false)
            setShowSuccess(true)
        })

    }

    return (
        <div className={"contact-us-page section"}>
            <Loader loading={sending}>
                <div className={"container-sm"}>
                    <h1>Contact Us</h1>
                    <p>We are The Codebuddy Team. We're here to help, so don't hesitate to reach out!</p>
                    <p>When reporting issues please include the operating system and platform you are using (VSCode/IntelliJ/PyCharm etc) as well as the language you are coding in.
                        If there are specific requests you have issues with, please include the request-id. You can get it by pressing the "Copy ID" button on a request.</p>
                    <Form className={"simple-grid bg-neutral-800 padding-md rounded"}>
                        <Label for="email">Your Email</Label>
                        <Input type="text" id="email" name="email" placeholder="Your Email" required
                               onChange={e => setEmail(e.target.value)}/>
                        <Label for="subject">Subject</Label>
                        <Input type="text" id="subject" name="subject" placeholder="Subject" required
                               onChange={e => setSubject(e.target.value)}/>
                        <Label for="message">Message</Label>
                        <Input id="message" name="message" placeholder="Message" required type={"textarea"}
                                  onChange={e => setMessage(e.target.value)}/>
                        <AnimatedButton onClick={submitForm}>Submit</AnimatedButton>
                    </Form>
                </div>
            </Loader>
            <NotifyPopup open={showSuccess} setOpen={setShowSuccess} onClose={navigateHome}>
                <h2>Success!</h2>
                <p>Your message has been sent. We will get back to you as soon as possible.</p>
            </NotifyPopup>
            <NotifyPopup open={showError} setOpen={setShowError}>
                <h2>Error!</h2>
                <p>Please fill out all the fields.</p>
            </NotifyPopup>
        </div>
    )
}