import {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import loader_img from "../images/loader_three-dots-white.svg"
import {PageContext} from "../PageContext";
import {useLocation} from "react-router-dom";

export function Loader(props: { loading: boolean, color?: string, size?: "sm", children: ReactNode }) {
    const loading = props.loading
    return (
        <>
            {
                loading && (
                    <div className={"loading-spinner"}>
                        <img src={loader_img} alt={"loading"}
                             className={"loading-indicator " + (props.size === "sm" ? "loading-indicator-sm" : "loading-indicator-lg")}/>
                    </div>
                )
            }
            {!loading && props.children}
        </>
    )
}

export function LoaderWithFetch<T>(props: {
    url: string,
    init?: RequestInit,
    color?: string,
    size?: "sm",
    children: (responseJson: T, refresh: () => void) => ReactNode
}) {
    const [loading, setLoading] = useState<boolean>(false)
    const [hasError, setHasError] = useState<boolean>(false)
    const [responseJson, setResponseJson] = useState<T | null>(null)

    const location = useLocation()
    const context = useContext(PageContext)

    const load = useCallback(() => {
        if (loading) {
            return
        }
        setLoading(true)
        fetchWithLoader(() => {
            setLoading(false)
            context.onLoadComplete()
        }, props.url, props.init).then(async (response) => {
            await processResponse(response, (json) => {
                setResponseJson(json as T)
            }, (errorKey, errorMessage) => {
                let redirectUrl = location.pathname + location.search
                context.handleError(errorKey, redirectUrl, errorMessage)
                setHasError(true)
            })
        })
    }, [props.url, props.init, context, location])

    useEffect(() => {
        load();
    }, [load]);

    return (
        <Loader loading={loading || hasError} color={props.color} size={props.size}>
            {responseJson && props.children(responseJson as T, load)}
        </Loader>
    )
}

export function fetchWithLoader(onLoadingDone: () => void, url: string, init?: RequestInit): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
        fetch(url, init).then((response) => {
            onLoadingDone()
            resolve(response)
        }).catch(e => {
            onLoadingDone()
            console.error(e)
            reject(e)
        })
    })
}

export async function processResponse(response: Response, onSuccess: (json: any) => void, onError: (errorKey: string, errorMessage?: string) => void) {
    if (!response.ok) {
        onError("bad_response", `Bad response code from server: ${response.status}`)
        return;
    }
    const json = await response.json() as Record<string, any>;
    const statusJson = json["status"];
    const errorMessageJson = json["errorMessage"]
    if (statusJson === "error" || errorMessageJson) {
        const errorJson = json["error"] || errorMessageJson;
        if (errorJson) {
            onError(errorJson, errorMessageJson)
            return
        }
    }
    onSuccess(json)
}