import {loadStripe} from "@stripe/stripe-js";
import {useLocation, useNavigate} from "react-router-dom";
import {ActionType} from "./PlansPage";
import {useEffect} from "react";
import {LoaderWithFetch} from "../components/Loader";

export interface CheckoutPlanState {
    planId: number;
    testMode: boolean;
    actionType: ActionType
}

interface CheckoutPlanResponse {
    redirect?: string;
    clientSecret?: string;
    stripePublishableApiKey?: string;
}

export default function CheckoutPlanPage() {
    const navigate = useNavigate();
    const location = useLocation()

    if (!location.state) {
        navigate('/plans')
        return null
    }

    const state = location.state as CheckoutPlanState

    const prefix = `${state.testMode ? "test/" : ""}`;

    const returnUrl = new URL(window.location.href);
    returnUrl.pathname = "/thankYou"
    const returnParam = new URLSearchParams({returnUrl: returnUrl.toString()}).toString()

    if (state.actionType === ActionType.renew) {
        window.location.href = `/${prefix}renewPlan?${returnParam}`
        return null
    } else if (state.actionType === ActionType.cancel) {
        window.location.href = `/${prefix}cancelPlan?${returnParam}`
        return null
    }

    const url = `/api/v1/${prefix}checkoutPlan?planId=${state.planId}&${returnParam}`;

    return (
        <LoaderWithFetch<CheckoutPlanResponse> url={url}>
            {response => {
                if (response.redirect) {
                    window.location.href = response.redirect
                    return null
                }
                const clientSecret = response.clientSecret!!
                const stripePublishableApiKey = response.stripePublishableApiKey!!

                return (
                    <Checkout clientSecret={clientSecret} stripePublishableApiKey={stripePublishableApiKey}/>
                )
            }}
        </LoaderWithFetch>
    )
}

export function Checkout(props: { clientSecret: string, stripePublishableApiKey: string }) {
    let navigate = useNavigate();
    useEffect(() => {
        loadStripe(props.stripePublishableApiKey).then((stripe) => {
            if (!stripe) {
                console.error("Failed to load Stripe")
                navigate('/plans', {state: {error: "Failed to load Stripe"}})
                return
            }

            stripe.initEmbeddedCheckout({
                clientSecret: props.clientSecret,
            }).then((checkout) => {
                checkout.mount('#checkout');
            });
        }).catch(e => {
            console.error(e)
            navigate('/plans', {state: {error: "Failed to load Stripe"}})
        })

    }, [props.clientSecret, props.stripePublishableApiKey, navigate]);

    return (
        <div id={"checkout"}/>
    )
}
