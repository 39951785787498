// noinspection JSUnusedGlobalSymbols

import React, {JSX, PropsWithChildren, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AnimatedButton from "./AnimatedButton";

export function ConfirmPopupWithTrigger(props: PropsWithChildren &
    {
        trigger: JSX.Element,
        onConfirm: () => void,
        yesText?: string,
        noText?: string
    }
) {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <>
            {React.cloneElement(props.trigger, {onClick: () => setIsOpen(true)})}
            <ConfirmPopup open={isOpen} setOpen={setIsOpen} onConfirm={props.onConfirm} yesText={props.yesText}
                          noText={props.noText}>
                {props.children}
            </ConfirmPopup>
        </>
    )
}

export function NotifyPopupWithTrigger(props: PropsWithChildren &
    {
        trigger: JSX.Element,
        onClose: () => void,
        okText?: string
    }
) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    return (
        <>
            {React.cloneElement(props.trigger, {onClick: () => setIsOpen(true)})}
            <NotifyPopup open={isOpen} setOpen={setIsOpen} onClose={props.onClose} okText={props.okText}>
                {props.children}
            </NotifyPopup>
        </>
    )
}

export function NotifyPopup(props: PropsWithChildren &
    {
        open: boolean,
        setOpen: (open: boolean) => void,
        onClose?: () => void,
        okText?: string
        title?: string
    }
) {

    const close = () => {
        props.setOpen(false)
        if (props.onClose) {
            props.onClose()
        }
    }

    let contents = (close: () => void) => {
        return (
            <>
                <ModalHeader>
                    {props.title ?? "Notification"}
                </ModalHeader>
                <ModalBody>
                    {props.children}
                </ModalBody>
                <ModalFooter>
                    <AnimatedButton onClick={close}>{props.okText ?? "Ok"}</AnimatedButton>
                </ModalFooter>
            </>
        )
    }
    return (
        <>
            <Modal isOpen={props.open} toggle={close} centered={true}>
                {contents(close)}
            </Modal>
        </>
    )
}

export function ConfirmPopup(props: PropsWithChildren &
    {
        open: boolean,
        setOpen: (open: boolean) => void,
        onClose?: () => void,
        onConfirm: () => void,
        yesText?: string
        noText?: string
        titleText?: string
    }
) {

    const close = () => {
        props.setOpen(false)
        if (props.onClose) {
            props.onClose()
        }
    }

    let contents = (close: () => void) => {
        return (
            <>
                <ModalHeader>
                    {props.titleText ?? "Confirm"}
                </ModalHeader>
                <ModalBody>
                    {props.children}
                </ModalBody>
                <ModalFooter>
                    <AnimatedButton onClick={() => {
                        close()
                        props.onConfirm()
                    }}>{props.yesText ?? "Yes"}</AnimatedButton>
                    <AnimatedButton onClick={close}>{props.noText ?? "No"}</AnimatedButton>
                </ModalFooter>
            </>
        )
    }
    return (
        <>
            <Modal isOpen={props.open} toggle={close} centered={true}>
                {contents(close)}
            </Modal>
        </>
    )
}