import discordLogo from "../images/discord-logo.svg";
import redditLogo from "../images/reddit-logo.svg";
import emailLogo from "../images/email-logo.svg";
import Carousel, {ResponsiveType} from "react-multi-carousel";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import React from "react";

class Testimonial {
    name: string
    avatar: string
    date: string
    content: string

    constructor(name: string, avatar: string, date: string, content: string) {
        this.name = name
        this.avatar = avatar
        this.date = date
        this.content = content
    }
}

const testimonials: Testimonial[] = [
    new Testimonial("tabloint", discordLogo, "4:28 AM · Sep 07, 2023", `I must say it's nice not having to pay someone $40 an hour for a full
workweek when this replaces a medium knowledge dev lol [...] testing out how well it can
build on stuff for a game environment, might just be able to finish my project without
hiring someone (i do 3d and texturing stuff, hate coding).`),
    new Testimonial("kkiran", redditLogo, "7:07 AM · Aug 30, 2023", `Was skeptic when it asked for a lot of access on GitHub. Created a new
account and experienced it for the first time. It is fabulous! Thank you. Hope it is priced
competitively so I can use it for hobby projects.`),
    new Testimonial("anonymous", emailLogo, "0:03 AM · Aug 31, 2023", `It was FANTASTIC! Absolute LIFESAVER!!! I was making a Roblox game with no
scripting experience (well i do have a bit but not enough to make a full game) so i decided
to use chatgpt to make the scripts. It worked for the most part but after a bit it started
barely following my instructions. When I found out about codebuddy and tried it, it
absolutely DESTROYED chatgpt and made awesome scripts. thank you so so much`),
    new Testimonial("hackinbosh", discordLogo, "1:54 PM · Sep 07, 2023", `Gotta say though I am generally loving it, as a product manager, being
able to prototype some basic ideas to a sort of proof of concept stage on my own is game
changing and I think will change a lot for people like me who have lots of ideas, but none
of the skills to actually write good code`),
    new Testimonial("Maxcrit", discordLogo, "0:12 AM · Feb 05, 2024", `Wow this is super interesting and works very well I love it! I don't know
why copilot can't do multiple file editing and such it is so useful`),
    new Testimonial("AceHighness", discordLogo, "08:42 PM - May 10th, 2024", "when I was using chatgpt i was doing a lot of prompt engineering. now im just talking to my friend over here, CodeBuddy. " +
        "He understands me, even when I'm a bit drunk sometimes, he still understands me. I dont have to change how I talk, and I just select a bunch of files, explain what I want ...  and hit 'do your magic'!!!"),
]

export function FrontpageTestimonials() {

    const responsive: ResponsiveType = {
        desktop7: {
            breakpoint: {max: 10000, min: 1760},
            items: 7
        },
        desktop6: {
            breakpoint: {max: 1760, min: 1500},
            items: 6
        },
        desktop5: {
            breakpoint: {max: 1500, min: 1200},
            items: 4
        },
        desktop4: {
            breakpoint: {max: 1500, min: 1200},
            items: 4
        },
        desktop3: {
            breakpoint: {max: 1200, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 600},
            items: 2
        },
        mobile: {
            breakpoint: {max: 600, min: 0},
            items: 1
        }
    }

    return (
        <div className="section py-3 testimonials">
            <Carousel responsive={responsive} autoPlay={true} infinite={true} arrows={false} showDots={true}>
                {
                    testimonials.map((testimonial, index) => (
                        <TestimonialCard key={index} testimonial={testimonial}/>
                    ))
                }
            </Carousel>
        </div>

    )
}

function TestimonialCard(props: { testimonial: Testimonial }) {
    return (
        <div className="testimonial-outer">
            <Card className="m-2" style={{width: "18rem"}}>
                <CardHeader>
                    <div className="d-flex align-items-center mb-2 mt-2">
                        <img src={props.testimonial.avatar} className="rounded-circle me-2" alt="Discord Logo"
                             style={{width: "48px", height: "48px"}}/>
                        <h6 className="card-title mb-0">{props.testimonial.name}</h6>
                    </div>
                </CardHeader>
                <CardBody>
                    <p className="card-text">"
                        {props.testimonial.content}
                    </p>
                </CardBody>
                <CardFooter>
                    <p className="card-text">{props.testimonial.date}</p>
                </CardFooter>
            </Card>
        </div>
    )
}