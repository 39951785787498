import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ConfirmPopup} from "../components/Popups";
import {TermsOfService} from "../components/TermsOfService";
import {PrivacyPolicy} from "../components/PrivacyPolicy";

export default function AcceptTermsAndConditionsPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const source = location.state?.source;

    const onAccept = () => {
        fetch("/api/v1/acceptTermsAndConditions", {method: "POST"})
            .then(() => {
                if (source) {
                    navigate(source);
                } else {
                    navigate("/");
                }
            });
    }

    return (
        <div className={"accept-tos-page"}>
            <TermsOfService/>
            <PrivacyPolicy/>
            <AcceptSection onAccept={onAccept}/>
        </div>
    )
}

function AcceptSection(props: { onAccept: () => void }) {
    const [showConfirm, setShowConfirm] = useState(false);
    return (
        <>
            <section className="section">
                <div className="container-xl">
                    <div className="section">
                        <div className="w-layout-grid column-lg max-width-full auto">
                            <h4>
                                By clicking the button below, you agree to the Terms of Service and Privacy Policy.
                            </h4>
                            <div className="w-layout-grid column-2">
                                <button onClick={() => setShowConfirm(true)} className="button-animated w-inline-block">
                                    <div className="button-label">Accept</div>
                                    <div className="button-hover-background"></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmPopup open={showConfirm} setOpen={setShowConfirm} onConfirm={props.onAccept}
                              yesText={"Accept Terms"} noText={"Cancel"} titleText={"Agreement"}>
                    Do you agree to the Privacy Policy and Terms of Service agreements as shown on this page?
                </ConfirmPopup>
            </section>
        </>
    )
}
