const githubOAuthUrl = 'https://github.com/login/oauth';
const clientId = '61c2a9e434c5e3f2452b';

export function initiateGithubSignInExtended(redirectUri?: string) {
    initiateSignIn('user repo', {}, redirectUri);
}

export function initiateGithubSignInLimited(redirectUri?: string) {
    initiateSignIn('user:email', {limited: "true"}, redirectUri);
}

function initiateSignIn(scopeName: string, additionalParams: Record<string, string>, redirectUri?: string) {
    if (!redirectUri || redirectUri === "") {
        redirectUri = '/startCoding';
    }
    const currentUrl = new URL(window.location.href)
    const redirectParams = new URLSearchParams();
    redirectParams.append("redirect_uri", currentUrl.origin + redirectUri);

    let githubAuthUrl = "/githubAuth";
    if (currentUrl.hostname === "localhost" || currentUrl.hostname === "127.0.0.1") {
        githubAuthUrl += "/dev";
        redirectParams.append("targetPort", currentUrl.port);
    }
    if (currentUrl.hostname === "test.codebuddy.ca") {
        githubAuthUrl += "/test";
    }

    for (const key in additionalParams) {
        redirectParams.append(key, additionalParams[key]);
    }

    redirectUri = `https://codebuddy.ca${githubAuthUrl}?${redirectParams}`;
    const targetParams = new URLSearchParams();
    targetParams.append("client_id", clientId);
    targetParams.append("redirect_uri", redirectUri);
    targetParams.append("scope", scopeName);
    const state = Math.random().toString(36).substring(7);
    targetParams.append("state", state);
    window.location.href = `${githubOAuthUrl}/authorize?${targetParams}`
}