import {MouseEventHandler, PropsWithChildren} from "react";

export default function AnimatedButton(
    props: PropsWithChildren & {
        onClick?: MouseEventHandler<HTMLButtonElement>
        className?: string
    }
) {

    return (
        <button className={`button-animated w-inline-block ${props.className ?? ""}`} onClick={props.onClick}>
            <div className="button-label">{props.children}</div>
            <div className="button-hover-background"></div>
        </button>
    )
}