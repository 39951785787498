import React, {useCallback, useEffect, useState} from 'react';
import './css/App.css';
import {NavHeader} from "./sections/NavHeader";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {NavFooter} from "./sections/NavFooter";
import {DefaultPageContext, PageContext, PageContextData} from "./PageContext";
import {fetchWithLoader} from "./components/Loader";
import {initiateGithubSignInExtended, initiateGithubSignInLimited} from "./services/GithubAuthService";
import {ConfirmPopup, NotifyPopup} from "./components/Popups";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";

interface CheckSessionResponse {
    isLoggedIn: boolean
    isAdmin?: boolean
    needsTosUpdate?: boolean
}

export interface ScrollTarget {
    targetId: string
    targetPath: string
}

function App() {
    const [pageContext, setPageContext] = useState<PageContextData>(DefaultPageContext);
    const [scrollTo, setScrollTo] = useState<ScrollTarget | null>(null);
    const [loading, setLoading] = useState<boolean>(false)
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false)
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [showNeedsLoginPopup, setShowNeedsLoginPopup] = useState<boolean>(false)
    const [needsLoginRedirectUri, setNeedsLoginRedirectUri] = useState<string | undefined>(undefined)
    const [showNeedsTosPopup, setShowNeedsTosPopup] = useState<boolean>(false)
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false)
    const [errorRedirectPath, setErrorRedirectPath] = useState<string>("/")
    const [error, setError] = useState<string>("")
    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [loginRedirectUri, setLoginRedirectUri] = useState<string | undefined>(undefined)

    const location = useLocation();
    const navigate = useNavigate();

    function clearLogin() {
        setLoggedIn(false)
        setIsAdmin(false)
    }

    const checkScroll = useCallback(() => {
        if (scrollTo && location.pathname === scrollTo.targetPath) {
            console.log("Trying to scroll to " + scrollTo.targetId)
            const success = scrollToId(scrollTo.targetId)
            if (success) {
                console.log("Successfully scrolled to " + scrollTo.targetId)
                setScrollTo(null)
            }
        }
    }, [location, scrollTo]);

    const onLoadComplete = useCallback(() => {
        checkScroll()
    }, [checkScroll])

    const checkLoggedIn = useCallback(() => {
        setLoading(true)
        fetchWithLoader(() => {
            setLoading(false)
            onLoadComplete()
        }, "/api/v1/checkSession").then(async (response) => {
            if (response.ok) {
                const json = await response.json() as CheckSessionResponse
                setLoggedIn(json.isLoggedIn)
                setIsAdmin(json.isAdmin === true)
                if (json.needsTosUpdate === true) {
                    setShowNeedsTosPopup(true)
                }
            } else {
                clearLogin();
            }
        }).catch(() => {
            clearLogin()
        });
    }, [onLoadComplete])

    function handleLogin(redirectUri?: string) {
        setLoginRedirectUri(redirectUri)
        setShowLogin(true)
    }

    async function handleLogout() {
        await fetch("/api/v1/logout", {method: "POST"})
        clearLogin()
    }

    function handleError(error: string, redirectTo: string, errorMessage?: string) {
        if (error === "not_logged_in") {
            clearLogin()
            setNeedsLoginRedirectUri(redirectTo)
            setShowNeedsLoginPopup(true)
        } else if (error === "tos_not_accepted") {
            setShowNeedsTosPopup(true)
            setErrorRedirectPath(redirectTo)
        } else if (error === "token_expired") {
            setShowErrorPopup(true)
            setErrorRedirectPath("/")
            setError("Your login token has expired. Try logging in again from the plugin.")
        } else {
            setShowErrorPopup(true)
            setErrorRedirectPath(redirectTo)
            if (errorMessage) {
                setError(`An unexpected error occurred: ${error}: ${errorMessage}`)
            } else {
                setError(`An unexpected error occurred: ${error}`)
            }
        }
    }

    function handleNeedsTosConfirm() {
        navigate("/acceptTOS", {state: {source: {...location}}})
    }

    useEffect(() => {
        checkLoggedIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPageContext({
            loggedIn: isLoggedIn,
            admin: isAdmin,
            checkLoggedIn: checkLoggedIn,
            handleError: handleError,
            onLoadComplete: onLoadComplete
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdmin, isLoggedIn]);

    useEffect(() => {
        checkScroll();
    }, [checkScroll]);

    useEffect(() => {
        if (location.hash) {
            if (location.hash.startsWith("#blog:")) {
                navigate(`/blog/${location.hash.substring('#blog:'.length)}`)
            } else {
                navigate(location.pathname, {
                    state: {
                        scrollTo: {
                            targetId: location.hash.substring(1),
                            targetPath: location.pathname
                        }
                    }
                })
            }
        }
    }, [location, navigate])

    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            setScrollTo(location.state.scrollTo)
        }
    }, [location]);

    const title = "Codebuddy: Not just an AI coding assistant"
    const description = "Codebuddy is revolutionizing coding by providing conversational interaction with your codebase, and multi-file creation and modification with GPT4."

    return (
        <HelmetProvider>
            <PageContext.Provider value={pageContext}>
                {
                    !scrollTo && (
                        <ScrollToTop/>
                    )
                }

                <Helmet prioritizeSeoTags defaultTitle={title}>
                    <title>Codebuddy: Not just an AI coding assistant</title>
                    <link rel="canonical" key="link-canonical" href="https://codebuddy.ca/"/>
                    <meta property="og:title" name="og:title" key="og:title" content={title}/>
                    <meta property="twitter:title" name="twitter:title" key="twitter:title" content={title}/>
                    <meta property="description" name="description" key="description" content={description}/>
                    <meta property="og:description" name="og:description" key="og:description" content={description}/>
                    <meta property="twitter:description" name="twitter:description" key="twitter:description" content={description}/>
                    <meta property="og:image" name="og:image" key="og:image" content="https://storage.googleapis.com/aicoder-resources/images/logo2c-codebuddy-1110px-square.png"/>
                    <meta property="og:url" name="og:url" key="og:url" content="https://codebuddy.ca/"/>
                    <meta property="og:type" name="og:type" key="og:type" content="website"/>
                    <meta property="twitter:card" name="twitter:card" key="twitter:card" content="summary_large_image"/>
                </Helmet>

                <div id={"top"} className="page-container">
                    <NavHeader loginLoading={loading} isLoggedIn={isLoggedIn} triggerLogin={() => handleLogin()}
                               triggerLogout={() => handleLogout()}/>
                    <div className={"page-content"}>
                        <Outlet/>
                    </div>
                    <NavFooter/>
                    <ConfirmPopup open={showNeedsLoginPopup} setOpen={setShowNeedsLoginPopup}
                                  onConfirm={() => handleLogin(needsLoginRedirectUri)}
                                  onClose={() => navigate("/")}
                                  titleText="Login Required"
                                  noText="Cancel"
                                  yesText="Login">
                        You need to be logged in to proceed.
                    </ConfirmPopup>
                    <ConfirmPopup open={showNeedsTosPopup} setOpen={setShowNeedsTosPopup}
                                  onConfirm={handleNeedsTosConfirm}
                                  onClose={() => navigate("/")}
                                  titleText="Terms of Service Update"
                                  noText="Cancel"
                                  yesText="Show TOS">
                        You need to accept the terms and conditions to proceed.
                    </ConfirmPopup>
                    <NotifyPopup open={showErrorPopup} setOpen={setShowErrorPopup} title={"Error"}
                                 onClose={() => navigate(errorRedirectPath)}>
                        <pre>{error}</pre>
                    </NotifyPopup>
                    <Modal isOpen={showLogin} toggle={() => setShowLogin(false)} centered={true}>
                        <ModalHeader close={(
                            <button onClick={() => setShowLogin(false)} className="close-button btn-close">
                                <i className="fas fa-times"/>
                            </button>
                        )}>
                            Login via Github
                        </ModalHeader>
                        <ModalBody>
                            <div className="login-buttons">
                                <div className="login-info">
                                    Log in with your Github Account here if you want to use the plugins. <br/>
                                    This only requires minimal permissions.
                                </div>
                                <button className="button-animated large"
                                        onClick={() => {
                                            setShowLogin(false)
                                            initiateGithubSignInLimited(loginRedirectUri)
                                        }}>
                                    Login with GitHub
                                </button>
                                <div className="login-info">
                                    Log in with your Github Account here if you want to be able to easily use the deprecated
                                    web version of Codebuddy. <br/>
                                    This requires access to your projects.
                                </div>
                                <button className="button-animated large"
                                        onClick={() => {
                                            setShowLogin(false)
                                            initiateGithubSignInExtended(loginRedirectUri)
                                        }}>
                                    Login with GitHub (extended)
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </PageContext.Provider>
        </HelmetProvider>
    );
}

export function scrollToId(targetId: string) {
    let element = document.getElementById(targetId);
    if (!element) {
        return false
    }
    window.scrollTo({
        top:
            element.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            50,
    })
    return true
}

export function ScrollToTop() {
    const [initialScroll, setInitialScroll] = useState<boolean>(true);
    const {pathname} = useLocation();

    useEffect(() => {
        if (initialScroll) {
            setInitialScroll(false);
            return;
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto"
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return null;
}

export default App;
