import React, {useContext, useEffect, useState} from "react";
import {PageContext} from "../PageContext";
import {useLocation, useNavigate} from "react-router-dom";
import {LoaderWithFetch} from "../components/Loader";
import {BgShape001} from "../components/BgShapes";
import {ProfileData} from "../dao/ProfileData";

export function OAuthApprovalPage() {
    const [redirectPort, setRedirectPort] = useState<number | null>(null)
    const [token, setToken] = useState<string | null>(null)
    const [sessionId, setSessionId] = useState<string | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [showSafariWaning, setShowSafariWarning] = useState<boolean>(false)
    const navigate = useNavigate()
    const location = useLocation()
    const pageContext = useContext(PageContext)
    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        const token = params.get("token");
        const redirectPort = params.get("redirectPort");
        if ((!token && !redirectPort) || (redirectPort && token)) {
            pageContext.handleError("Not a valid auth request", "/")
            return
        }
        if (redirectPort) {
            try {
                setRedirectPort(parseInt(redirectPort))
            } catch (e) {
                pageContext.handleError("bad_url", "/")
            }
        } else if (token) {
            setToken(token)
        }

        const cookies = document.cookie.split(";").map(cookie => cookie.trim().split("="))
        const sessionId = cookies.find(cookie => cookie[0] === "JSESSIONID")?.[1]
        if (sessionId) {
            setSessionId(sessionId)
        }

    }, [pageContext]);

    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setShowSafariWarning(isSafari)
    }, []);

    const authorize = () => {
        if (token) {
            authorizeToken()
        } else {
            authorizeRedirectPort()
        }
    }

    const authorizeToken = () => {
        fetch("/api/v1/confirmLoginToken", {
            method: 'POST',
            body: new URLSearchParams({token: token!!})
        }).then((response) => {
            if (!response.ok) {
                let errorMessage = 'Authorization failed: ';
                switch (response.status) {
                    case 0:
                        errorMessage += 'Request timed out or unable to connect.';
                        break;
                    case 400:
                        errorMessage += 'Bad Request.';
                        break;
                    case 401:
                        errorMessage += 'Unauthorized. Please check your credentials.';
                        break;
                    case 500:
                        errorMessage += 'Internal Server Error. Please try again later.';
                        break;
                    default:
                        errorMessage += 'There was an error processing the request. Error code ' + response.status;
                }
                setErrorMessage(errorMessage)
                return;
            }
            response.json().then((data) => {
                const redirectTo = location.pathname + location.search
                if (data.status === "success") {
                    closeWindow();
                } else if (data.status === "token_expired") {
                    setErrorMessage(data.errorMessage)
                } else if (data.error) {
                    pageContext.handleError(data.error, redirectTo)
                } else {
                    pageContext.handleError(`Unexpected status from server: ${data.status}`, redirectTo)
                }
            }).catch((e) => {
                console.error(e)
                setErrorMessage(`Authorization failed: ${e.message}`)
            })
        }).catch((e) => {
            console.error(e)
            setErrorMessage(`Authorization failed: ${e.message}`)
        })
    }

    const authorizeRedirectPort = () => {
        setErrorMessage(null)
        const urlParams = new URLSearchParams({sessionId: sessionId!!});
        fetch(`http://127.0.0.1:${redirectPort}/auth`, {
            method: 'POST',
            body: urlParams
        }).then((response) => {
            if (!response.ok) {
                let errorMessage = 'Authorization failed: ';
                switch (response.status) {
                    case 0:
                        errorMessage += 'Request timed out or unable to connect.';
                        break;
                    case 400:
                        errorMessage += 'Bad Request.';
                        break;
                    case 401:
                        errorMessage += 'Unauthorized. Please check your credentials.';
                        break;
                    case 403:
                        errorMessage += 'Forbidden. You do not have permission to access this resource.';
                        break;
                    case 404:
                        errorMessage += 'Not Found. The requested resource could not be found.';
                        break;
                    case 500:
                        errorMessage += 'Internal Server Error. Please try again later.';
                        break;
                    default:
                        errorMessage += 'There was an error processing the request. Error code ' + response.status;
                }
                setErrorMessage(errorMessage)
                return;
            }
            closeWindow();
        }).catch((e) => {
            console.error(e)
            setErrorMessage(`Authorization failed: ${e.message}`)
        })
    }

    function closeWindow() {
        window.close();
        setTimeout(() => {
            navigate("/");
        }, 200);
    }

    const cancel = () => {
        closeWindow();
    }

    return (
        <>
            <LoaderWithFetch<ProfileData> url={`/api/v1/getLoginInfo` + (token ? `?token=${token}` : "")}>
                {(loginInfo) =>
                    (
                        <>
                            <div className="oauth-approval container-sm">
                                <div className='text-center oauth-image'>
                                    <img src="https://storage.googleapis.com/aicoder-resources/images//logo2c-codebuddy-textbottom-256px.png" alt="Code Buddy Logo"/>
                                </div>
                                <div className='text-center oauth-header'>
                                    <h4>Codebuddy Access Request</h4>
                                </div>
                                <div className='text-center oauth-body'>
                                    <p>A plugin is attempting to access the account that belongs to <strong>{loginInfo.username}</strong>.</p>
                                </div>

                                <div className='text-center oauth-body'>
                                    <p>
                                        By clicking "Authorize", you are allowing a plugin to access your account. Be sure you requested this before clicking Authorize.
                                    </p>
                                </div>
                                {
                                    showSafariWaning && (
                                        <div className='text-center oauth-body'>
                                            <p className="safari-warning">There have been reports that Safari has issues with authorizing the plugin. If you are having problems, try opening this page in
                                                another browser.</p>
                                        </div>
                                    )
                                }
                                <div className="oauth-buttons">
                                    <button className="button-animated large" onClick={authorize}>Authorize</button>
                                    <button className="button-animated large" onClick={cancel}>Cancel</button>
                                </div>
                                <div className='text-center'>
                                    <p className='error-message'>{errorMessage}</p>
                                </div>
                            </div>
                        </>
                    )
                }
            </LoaderWithFetch>
            <div className="bg-pattern-wrap centered"><BgShape001/></div>
        </>
    )
}