// noinspection HtmlUnknownAnchorTarget,HtmlUnknownTarget

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import AnimatedButton from "../components/AnimatedButton";
import logo from '../images/logo2b-codebuddy-64px-textleft1.png';
import {Loader} from "../components/Loader";
import {Collapse, Nav, Navbar, NavbarToggler, NavItem} from 'reactstrap';

export function NavHeader(props: {
    loginLoading: boolean,
    isLoggedIn: boolean,
    triggerLogin: () => void,
    triggerLogout: () => void,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const close = () => setIsOpen(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            let navbarElement = document.getElementById("navbar");
            if (navbarElement && event.target) {
                if (!navbarElement.contains(event.target as Node)) {
                    setIsOpen(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Navbar color="transparent" dark expand="md" container={false} id={"navbar"}>
            <div className="container-fluid">
                <div className="navbar-list">
                    <Link to={"/"} state={{scrollTo: {targetId: "top", targetPath: "/"}}}
                          className="brand w-nav-brand toggle-hide-mobile">
                        <img src={logo} loading="lazy" alt="" className="image-contain"/>
                    </Link>
                    <NavbarToggler onClick={toggle}>
                        <i className="fa fa-bars"></i>
                    </NavbarToggler>
                    <Collapse isOpen={isOpen} navbar onClick={close}>
                        <Nav className="ml-auto" navbar>
                            {
                                props.isLoggedIn && (
                                    <>
                                        <NavItem>
                                            <Link to={"/startCoding"} className="nav-link _1st w-nav-link">Start
                                                Coding</Link>
                                        </NavItem>
                                    </>
                                )
                            }
                            <NavItem>
                                <Link to={"/"} state={{scrollTo: {targetId: "top", targetPath: "/"}}}
                                      className="nav-link _1st w-nav-link">Home</Link>
                            </NavItem>
                            <NavItem>
                                <Link to={"/"} state={{scrollTo: {targetId: "features", targetPath: "/"}}}
                                      className="nav-link _1st w-nav-link">Features</Link>
                            </NavItem>
                            <NavItem>
                                <Link to={"/"} state={{scrollTo: {targetId: "pricing", targetPath: "/"}}}
                                      className="nav-link _1st w-nav-link">Pricing</Link>
                            </NavItem>
                            <NavItem>
                                <Link to={"/blog"} className="nav-link _1st w-nav-link">Blog</Link>
                            </NavItem>
                            {
                                props.isLoggedIn && (
                                    <>
                                        <NavItem>
                                            <Link to={"/profile"} className="nav-link _1st w-nav-link">Profile</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to={"/plans"} className="nav-link _1st w-nav-link">Plans & Billing</Link>
                                        </NavItem>
                                    </>
                                )
                            }
                        </Nav>
                    </Collapse>
                    <div className="navbar-controls navbar-login">
                        <Loader loading={props.loginLoading} size={"sm"}>
                            {
                                props.isLoggedIn && (
                                    <AnimatedButton onClick={props.triggerLogout}>Logout</AnimatedButton>
                                )
                            }
                            {
                                !props.isLoggedIn && (
                                    <AnimatedButton onClick={props.triggerLogin}>Signup&nbsp;/&nbsp;Login</AnimatedButton>
                                )
                            }
                        </Loader>
                    </div>
                </div>
                <div className="navbar-blur"></div>
            </div>
            <div className="navbar-blur"></div>
        </Navbar>
    )
}
