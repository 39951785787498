import React from 'react';
import ReactDOM from 'react-dom/client';
import "./css/normalize.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-multi-carousel/lib/styles.css';
import "./css/webflow.css";
import "./css/codebuddy-d1d1f8.webflow.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";

import ProfilePage from "./pages/ProfilePage";
import Frontpage from "./pages/Frontpage";
import CheckoutPlanPage from "./pages/CheckoutPlanPage";
import PlansPage from './pages/PlansPage';
import BlogListPage from './pages/BlogListPage';
import BlogEntryPage from "./pages/BlogEntryPage";
import ErrorPage404 from "./pages/ErrorPage404";
import StartCodingPage from "./pages/StartCodingPage";
import ThankYouPage from "./pages/ThankYouPage";
import AcceptTermsAndConditionsPage from "./pages/AcceptTermsAndConditionsPage";
import CheckoutCreditPurchasePage from "./pages/CheckoutCreditPurchasePage";
import {ContactUsPage} from "./pages/ContactUsPage";
import {OAuthApprovalPage} from "./pages/OAuthApprovalPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App/>} errorElement={<ErrorPage404/>}>
            <Route path="/" element={<Frontpage/>}/>
            <Route path="/blog" element={<BlogListPage/>}/>
            <Route path="/blog/:id" element={<BlogEntryPage/>}/>
            <Route path="/profile" element={<ProfilePage/>}/>
            <Route path="/plans" element={<PlansPage/>}/>
            <Route path="/checkoutPlan" element={<CheckoutPlanPage/>}/>
            <Route path="/checkoutCreditPurchase" element={<CheckoutCreditPurchasePage/>}/>
            <Route path="/startCoding" element={<StartCodingPage/>}/>
            <Route path="/thankYou" element={<ThankYouPage/>}/>
            <Route path="/contact" element={<ContactUsPage/>}/>
            <Route path="/acceptTOS" element={<AcceptTermsAndConditionsPage/>}/>
            <Route path="/terms-of-service" element={<TermsOfServicePage/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
            <Route path="/oauth-approval" element={<OAuthApprovalPage/>}/>
        </Route>
    )
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
