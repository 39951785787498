import {NavLink} from "react-router-dom";
import {BgShape001} from "../components/BgShapes";

export default function ErrorPage404() {
    return (
        <div className="utility-page-wrap">
            <div className="utility-page-content">
                <div className="margin-bottom margin-lg">
                    <h2 className="font-title">Page Not Found</h2>
                </div>
                <p>The page you are looking for doesn't exist or has been moved</p>
                <NavLink to={"/"} className="button button-outline w-button">Go back home</NavLink>
            </div>
            <div className="bg-pattern-wrap centered">
                <BgShape001/>
            </div>
        </div>
    )
}