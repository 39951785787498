import {createContext} from "react";

export interface PageContextData {
    loggedIn: boolean,
    admin: boolean,
    checkLoggedIn: () => void,
    handleError: (error: string, redirectTo: string, errorMessage?: string) => void
    onLoadComplete: () => void
}

export const DefaultPageContext: PageContextData = {
    loggedIn: false,
    admin: false,
    checkLoggedIn: () => {
    },
    onLoadComplete: () => {
    },
    handleError: () => {
    },
}


export const PageContext = createContext<PageContextData>({
    ...DefaultPageContext
})