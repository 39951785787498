import React, {useEffect, useRef, useState} from "react";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "./Videobox.css";

export interface VideoBoxProps {
    videoSrc: string;
    fullSizeVideoSrc?: string;
}

export function VideoBox(props: VideoBoxProps) {
    const [open, setOpen] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting && entry.intersectionRatio === 1) {
                    videoRef.current?.play();
                } else {
                    videoRef.current?.pause();
                    videoRef.current!.currentTime = 0;
                }
            },
            {
                threshold: 1.0
            }
        );
        const observedVideo = videoRef.current;
        if (observedVideo) {
            observer.observe(observedVideo);
            return () => {
                observer.unobserve(observedVideo);
            };
        }
    }, []);

    return (
        <div>
            <video preload="none" ref={videoRef} src={props.videoSrc} width="600" height="338" loop muted onClick={() => setOpen(true)}>
                Your browser does not support the video tag.
            </video>

            {open && (
                <Lightbox
                    plugins={[Video]}
                    slides={[{type: "video", autoPlay: true, sources: [{type: "video/mp4", src: props.fullSizeVideoSrc ? props.fullSizeVideoSrc : props.videoSrc}], width: 1200, height: 676}]}
                    open={open}
                    close={() => setOpen(false)}
                    controller={{closeOnBackdropClick: true}}
                />
            )}
        </div>
    );
}


export function ImageBoxAltFullscreen(props: { resource: string, fullSizeResource?: string, className?: string }) {
    const [open, setOpen] = useState(false);
    const isVideo = props.resource.endsWith('.mp4');
    const isFullResourceVideo = props.fullSizeResource?.endsWith('.mp4');

    return (
        <div className={`lightbox-link w-inline-block w-lightbox cursor-pointer ${props.className ?? ""}`}>
            <div className="image-wrapper-4-3 rounded-xs fade-in-on-scroll" onClick={() => setOpen(true)}>
                {isVideo ? (
                    <video preload="none" src={props.resource} className="image-bg-contain"/>
                ) : (
                    <img src={props.resource} loading="lazy" className="image-bg-contain" alt=""/>
                )}
            </div>
            {open && (
                isFullResourceVideo ? (
                    <Lightbox
                        plugins={[Video]}
                        open={open}
                        close={() => setOpen(false)}
                        // @ts-ignore
                        slides={[{type: "video", src: props.fullSizeResource ? props.fullSizeResource : props.resource}]}
                        controller={{closeOnBackdropClick: true}}
                    />
                ) : (
                    <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={[{src: props.fullSizeResource ? props.fullSizeResource : props.resource}]}
                        controller={{closeOnBackdropClick: true}}
                    />
                )
            )}
        </div>
    );
}